.App {
  text-align: center;
}

h1, h2, h3, h4 {
  font-family: "CormorantGaramond-Bold";
}

p {
  font-family: "Roboto-Regular";
  line-height: 1.6rem;
}

label, li, a {
  font-family: "Roboto-Regular";
} /* this affects every element in the body and the body itself */

.container {
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.page {
  min-height: 101vh;
}

.link {
  color: #000;
  font-weight: bold;
}

@font-face {
  font-family: 'Antonio-Bold';
  src: local('Antonio-Bold'), url(../fonts/Antonio-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'CrimsonPro-VariableFont_wght';
  src: local('CrimsonPro-VariableFont_wght'), url(../fonts/CrimsonPro-VariableFont_wght.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'CormorantGaramond-Bold';
  src: local('CormorantGaramond-Bold'), url(../fonts/CormorantGaramond-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(../fonts/Roboto-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(../fonts/Roboto-Bold.ttf) format('truetype');
  font-display: swap;
}

img {
  vertical-align: middle;
}

@media (min-width: 1206px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1008px) and (max-width: 1205px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 769px) and (max-width: 1007px) {
  .container {
    max-width: 720px;
  }
}

@media (max-width: 768px) {
  .carousel .slide img {
    height: "auto";
    max-height: 100vh;
    object-fit: cover;
    width: "100%";
  }
  ul.nav {
    display: block !important;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 26px;
  }
  .faqItem {
    margin: 12px 0;
    padding: 12px;
    border: 1px solid #425664;
    border-radius: 4px;
    background: #F6F4F2;
  }
  .faqTitle {
    font-size: 16px;
    color: #272727;
  }
  .faqItemIcon {
    height: 32px;
    width: 32px;
  }
  .toTopButton {
    border: 2px solid #425664;
    border-radius: 40px;
    color: #272727;
    font-weight: bold;
    background-color: white;
    position: relative;
    top: 0;
    transition: top ease 0.3s;
    margin-top: 26px;
    margin-bottom: 32px;
    height: 48px;
    width: 48px;
  }
  .toTopButtonIcon {
    height: 20px;
    width: 20px;
  }
  .emailFailIcon {
    height: 80px;
    width: 80px;
  }
  .emailSuccessIcon {
    height: 115px;
    width: 115px;
  }
}


@media (min-width: 576px) and (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 769px) {
  .carousel .slide img {
    height: "auto";
    max-height: 100vh;
    object-fit: cover;
    width: "100%";
  }
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 32px;
  }
  .faqItem {
    margin: 12px 0;
    padding: 20px;
    border: 1px solid #425664;
    border-radius: 4px;
    background: #F6F4F2;
  }
  .faqTitle {
    font-size: 20px;
    color: #272727;
  }
  .faqItemIcon {
    height: 46px;
    width: 46px;
  }
  .toTopButton {
    border: 2px solid #425664;
    border-radius: 40px;
    color: #272727;
    font-weight: bold;
    background-color: white;
    position: relative;
    top: 0;
    transition: top ease 0.3s;
    margin-top: 32px;
    margin-bottom: 42px;
    height: 60px;
    width: 60px;
  }
  .toTopButtonIcon {
    height: 26px;
    width: 26px;
  }
  .emailFailIcon {
    height: 100px;
    width: 100px;
  }
  .emailSuccessIcon {
    height: 144px;
    width: 144px;
  }
}

h1, h2 {
  color: #425664;
  letter-spacing: 0.1em;
  text-align: center;
}

h3 {
  font-size: 0.75rem;
  color: #272727;
  letter-spacing: 0.1em;
  font-family: Montserrat-Regular;
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel .slide img {
  object-fit: cover;
  width: auto;
}

button.control-arrow:before {
  display: none !important;
}

.leftSideMenu {
  float: right;
  display: flex;
}

.rightSideMenu {
  float: left;
  display: flex;
}

.burgerMenuItem:hover {
  filter: opacity(0.8);
}

.arrow-down {
  transition: all .25s ease-in;
  opacity: .4;
}

.arrow-down:hover {
  opacity: 1;
}

.formInput {
  width: 100%;
    padding: 12px;
    margin: 6px 0 4px;
    border: 1px solid #ccc;
    background: #fafafa;
    font-family: sans-serif;
    font-size: 12px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
}

input, label {
  display: block;
}

input[type=submit] {
  color: white;
  font-weight: bold;
  background-color: #C6AD8F;
}

input[type=submit]:hover {
  filter: opacity(0.85);
}
 
/* Tab Navigation */
ul.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}
 
ul.nav li {
  padding: 16px;
  padding-bottom: 8px;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  color: #425664;
  font-family: "Antonio-Bold";
  letter-spacing: 0.05rem;
}
 
ul.nav li.active {
  color: #425664;
}

.stripe {
  width: "100%";
  height: 6px;
  background: #425664;
  position: absolute;
  transition: all 0.5s;
  border-radius: 6px;
}

.aboutLi {
  float: left;
  width: 50%;
}

.toTopButton:hover {
  top: -4px;
}

.toTopButtonSimple {
  border: 2px solid white;
  border-radius: 40px;
  color: #272727;
  font-weight: bold;
  background-color: #425664;
  position: relative;
  top: 0;
  margin-top: 16px;
  float: right;
  height: 50px;
  width: 50px;
}

.toTopButtonSimple:hover {
  background-color: #4d6575;
}

.feedback {
  margin-left: 12px;
  margin-right: 12px;
}
